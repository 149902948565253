import { base_url } from "../../config/env"

//                               ===============NAV
export const nav_tooltip_messages = {
    open_setting: "Change Theme", download_cv: "Download Resume", dark_mode: "Use dark theme", day_mode: "Use Light theme",
    close: "Close", selected_thm: "Current theme", default_thm: "Default theme", use_thm: "Use this theme",
    selected_font: "Current font style", default_font: "Default font style", use_font: "Use this font style", v_align: "Re-Allign"
}
//export const resume = 'https://gurf-vi-s3-bucket1.s3.ap-south-1.amazonaws.com/cv/cvVishalM.pdf' // cvVishal_M.pdf or cvVishalM.pdf or CvVishalM.pdf 
//export const resume = 'https://vistg4.blob.core.windows.net/opcvs/cvVishalM.pdf' // ms azr opn
//export const resume = `${base_url}/cv` // download from server directory
export const resume = 'https://objectstorage.sa-valparaiso-1.oraclecloud.com/p/aNSwdwveKej2LAZaTa6CbTL5dYjqCDhVBPtDhJeQ4eOqoCZhUogzg9mgsNjIuxxB/n/axe1ujtjjc1w/b/Almira/o/pb/cv/cvVishalM.pdf' // read access expiring 30nov2027
//https://axe1ujtjjc1w.objectstorage.sa-valparaiso-1.oci.customer-oci.com/p/efluViU_seTGE2JwJdoI-4jEwv7nCtJEj2RcyikEmAG4r-1e7_2aITgDYIq4Voat/n/axe1ujtjjc1w/b/Almira/o/
//                                  ==================HOME
export const home_tooltip_messages = {
    about: "Navigate to About"
}

//                                  ==================ABOUT
export const about_text =
{
    name: "", desg: "", range: "", type: "",
    info: `Hello, I am Vishal Maurya, a passionate React, React Native, Spring Boot and Node Developer. I am currently employed at Amalgamated Banks of South Africa (ABSA), where I work on both client-side and server-side projects and have successfully delivered various mobile and web applications. I have developed mine expertise working with client-side & Server-side applications, I possess hands-on experience with Spring Boot, Node and AWS server deployments, hosting, and related server setups using Linux. Prior to my stint at ABSA, I worked as Software Engineer for NurseIO, Proctur, Cosmo Info Soft, Before that, I gained valuable knowledge and experience as an intern software trainee at a MERN stack BootCamp. I also worked as an intern for Javascript and React. Thank you for your time, and I hope to collaborate with you soon.`
}

export const exp = [
    {
        name: "Amalgamated Banks of South Africa (ABSA)", desg: "Senior Software Engineer", range: "2024-Present", type: "exp",
        info: "Working at ABSA as a Full-stack developer role using Spring Boot, React Native & React as Primary Tech Stack, Succesfully delivered and maintaining ABSA's mobile Banking Fintech Applications along with its Website"
    },
    {
        name: "NurseIO, Arizona US", desg: "Software Engineer", range: "2023-2024", type: "exp",
        info: "Worked at NurseIO as a Full-stack developer, Created Mobile Apps and various features as per requirements alongwith its Server side part, primarily used React, React Native, Spring Boot & Node- Express"
    },
    {
        name: "Proctur, Pune IN", desg: "Software Engineer", range: "2021-2023", type: "exp",
        info: "Wroked at Proctur as a React, React Native, and Node Developer at Proctur since 2021. In my role, I have developed and maintained web and mobile applications using these technologies. My responsibilities include writing clean and efficient code, collaborating with cross-functional teams, and ensuring high-performance and scalability of the applications. During my time at Proctur, I have gained valuable experience in building complex web and mobile applications that meet client requirements while adhering to industry best practices. Additionally, I have developed strong problem-solving and communication skills, allowing me to contribute to a highly productive and collaborative work environment."
    },
    {
        name: "Cosmo Info Solution, Lucknow, India", desg: "Freelancer MERN STACK", range: "2021 (6 mos)", type: "exp",
        info: "From 2021, I worked as a freelance developer with expertise in MERN Stack. During this 6-month period, I developed web applications that incorporated MongoDB, Express, React, and Node.js. I learned how to manage projects independently and deliver high-quality work while collaborating with clients to meet their unique requirements. My experience as a freelancer allowed me to develop a versatile skill set that I continue to leverage in my current role."
    },
    {
        name: "MERN Intern at WAC,Delhi IN", desg: "Software Trainee", range: "2020 (3 mos)", type: "exp",
        info: "I worked as a software trainee at WAC in Delhi, India, where I completed a MERN Stack bootcamp in 2020. During my three-month tenure, I acquired valuable knowledge and skills in web development and learned to work effectively in a team environment."
    },
    {
        name: "JavaScript BootCamp, Delhi IN", desg: "Intern", range: "2020 (3 mos)", type: "exp",
        info: "I completed a 3-month internship after joining a JavaScript Bootcamp in Delhi, India, where I gained my first exposure to various technologies and developed foundational knowledge of web development."
    },
]
export const edu = [
    {
        name: "MCA, HBTU KANPUR", range: "2018-2021", type: "edu",
        info: "I completed my Masters in Computer Applications from Harcourt Butler Technical University, HBTI Kanpur in 2021. During my time there, I gained invaluable technical experience and participated in a variety of extracurricular activities. Through my coursework and involvement in competitive programming, I developed a strong foundation in programming that has served me well in my career as a developer."
    },
    {
        name: "B.S (Physics & Mathematics),MGKVP", range: "2015-2018", type: "edu",
        info: "I am a mathematically inclined individual with a Bachelor's degree in Mathematics and Physics earned in 2018. My academic background has strengthened my understanding and application of mathematical concepts."
    },
    {
        name: "Kendriya Vidyalaya, MGS", range: "2003-2015", type: "edu",
        info: "I am a proud scholar of Kendriya Vidyalaya, MGS, where I received a high-quality education that laid the foundation for my academic and personal growth."
    },
]





//                                        =======================Work
export const work_traits = ["Back-end", "App", "Web"]
//export const work_traits = ["Web", "App", "Be", "App_n_Back-end", "Web_n_Back-end", "App_n_Web_n_Back-end"]

export const work_title = "Open"
export const work = [
    {
        type: "App_n_Back-end", name: "Spark By ABSA - Mobile Banking App", link: "https://play.google.com/store/apps/details?id=com.barclays.absa.banking", time: "2024", skills: ["Spring Boot", "Rect Native", "PostgreSQL"], role: "React Developer",
        info: "Spark App is an Wallet cum Mobile Banking App by Fintech ABSA Group Limited is an end-to-end secure and robust solutions for African Banking users."
    }
    , {
        type: "App_n_Back-end", name: "Timiza By ABSA - Mobile Banking App", link: "https://play.google.com/store/apps/details?id=ke.co.absa.digitalwallet", time: "2024", skills: ["React Native", "Spring Boot", "PostgreSQL", "Azure"], role: "React Developer",
        info: "Timiza is again a Wallet cum Mobile Banking App By ABSA Group Limited. Timiza provides a secure Mobile Banking Solution for Kenyan Customers and its comprise of every modern Banking facilities in order to smooth Banking Experience to our end users."
    }
    , {
        type: "Web_n_Back-end", name: "Banking Back Office- Employee Side Website", link: "", time: "2024", skills: ["React.js", "Spring Boot", "PostgreSQL", "Azure"], role: "React Developer",
        info: "Mainly used for resolving customers issues through tickets, and to verify and Approve new KYCs, to register/approval for new Accounts, Account blocking and Unblocking and much more."
    }
    , {
        type: "App_n_Back-end", name: "NurseIO - Healthcare Solutions - Mobile App", link: "https://play.google.com/store/apps/details?id=com.nurseio", time: "2023", skills: ["React Native","Spring Boot", "PosgtreSQL", "AWS"], role: "React Developer",
        info: "Healthcare staffing Solutions - Cross Platform Mobile Applications using React Native, Spring Boot for back-end, PostgreSQL as database, Seamless integration for efficient management of healthcare personnel."
    }
    , {
        type: "Web", name: "NurseIO - Healthcare Solutions Website", link: "https://app.nurseio.com", time: "2023", skills: ["React", "Next", "Redux", "Tailwind"], role: "React Developer",
        info: "Healthcare staffing platform using Next.js for web & Seamless integration forefficient management of healthcare personnel. Helping 100K+ Hospitals and Medical Facilities to smooth conduct their daily important  computerised tasks."
    },
    {
        type: "Web_n_Back-end", name: "Student Web Panel", link: "https://estudde.com/", time: "2023", skills: ["React.js","Spring Boot", "PostgreSQL", "Redux"], role: "React Developer",
        info: "Designed on React.js & Spring Boot, An end-to-end web solution for an student of a School/Inst/Coaching to perform all of theirs routine tasks e.g Online Exams,Purchase courses,Attend live classes,View and download Reports,View Schedules,See notification,Send messages,Raise query,Find schedule, Recording,Class Recording Vimeo and Video-Cipher, View result,Parents Login, etc"
    }
    , {
        type: "Web_n_Back-end", name: "Products Selling E-commerce", link: "https://pratik.proctur.com", time: "2022", skills: ["React.js", "Node.js", "PostgreSQL", "AWS"], role: "React Developer",
        info: "Built on React.js and Node.js, is a robust and dynamic platform for individuals, schools, and institutes to sell their courses online. With the integration of payment gateways like Razorpay and PayPal, users can safely and easily purchase and sell courses. Additionally, our platform incorporates VideoCipher and Vimeo implementation to ensure secure and reliable video hosting and delivery. Whether you're an expert looking to monetize your knowledge or an institution seeking to provide online learning opportunities, our web app offers a seamless and efficient solution for all your course-selling needs."
    }
    , {
        type: "App_n_Web_n_Back-end", name: "Auto APK Generation Website", link: "http://arp3.proctur.com/", time: "2023", skills: ["React.js", "Node - Express", "React Native", "AWS"], role: "React Developer",
        info: "Our innovative web app, powered by React.js, Node.js, and React Native, simplifies the process of publishing React Native client apps to the Google Play Store. Our platform automatically replaces all necessary client resources, settings, and certificates, including the keystore and bundle identifier saved in an S3 bucket. Additionally, all necessary App gradle/build shell commands are executed on the server. With Google APIs, publishing the app to production is a breeze, requiring just a single key press from the front-end. "
    }
    , {
        type: "App_n_Back-end", name: "ERP Class & Exam Management Mobile App -Teacher End", link: "https://play.google.com/store/apps/details?id=com.proctur.pro", time: "2023", skills: ["React Native","Spring Boot", "PosgtreSQL", "AWS"], role: "React Developer",
        info: "Powered by React Native & Spring Boot, An end-to-end Native (Android & IOS) App solution for an student of a School/Inst/Coaching to perform all of theirs routine tasks e.g Online Exams,Purchase courses,Attend live classes,View and download Reports,View Schedules,See notification,Send messages,Raise query,Find schedule, recording, View result"
    }
    , {
        type: "App_n_Back-end", name: "Proctur Student Side App", link: "https://play.google.com/store/apps/details?id=com.proctur.procturlearnstudent", time: "2023", skills: ["React Native", "Spring Boot", "AWS"], role: "React Developer",
        info: "Built with React Native & Spring Boot, An Enhanced & Detailed Native (Android & IOS) App solution for a School/Inst/Coaching to perform all of theirs routine tasks e.g, Online Exams,Sell courses,Schedule live classes,Send Reports,Send Notification and Emails,View Schedules,Send notification,Send messages and mails,Answer query,Find schedule,Class Recording Vimeo and Video-Cipher, Send results,Mark Attendance,Admin Login,Faculty Login,Moderator's Login,Inventory management, many more"
    }
    , {
        type: "App", name: "Exam Managemant App", link: "https://play.google.com/store/apps/details?id=com.proctur.live", time: "2022", skills: ["React Native"], role: "React Developer",
        info: "Our Exam Management Lite App, developed using React Native, offers users a comprehensive solution for managing and conducting offline exams. With a range of features including attendance marking, fees management, marks updates, report downloads, certificate distribution, parent-teacher e-meetings, and classes management, our platform is designed to simplify the exam management process. Our user-friendly and intuitive app is perfect for educational institutions seeking to improve their exam management processes, providing a powerful and efficient tool for better control and management of exams. With our app, users can better manage and conduct exams, streamlining the process and saving time and effort."
    }
    // , {
    //     type: "App", name: "Live Exam Web - Student Side", link: "web.proctur.com", time: "2021", skills: ["React.js", "Angular", "AWS"], role: "React Developer",
    //     info: "This exam panel is a powerful tool for students to take exams in a fair and secure manner. Built on React.js, it allows students to take live exams, practice with mock tests, and download instant results. With features like AI-based proctoring, voice detection, tab change warnings, and copy-paste detection, it ensures that students cannot cheat during exams. The platform also supports different types of exams, including subjective and objective, making it suitable for a wide range of subjects. The admin can customize the platform to meet their specific needs, including turning on instant result settings. Overall, this exam panel is a reliable and efficient way for students to take exams securely and fairly."
    // }
    , {
        type: "Web", name: "Exam Management Desk Admin side", link: "https://onlinetest.proctur.com/administrator/dashboard", time: "2021", skills: ["React.js", "Angular", "AWS"], role: "React Developer",
        info: "This web portal, developed using React.js, is a comprehensive e-solution for school and institute admins to create and conduct exams. With features like test and question creation, saving options, multilingual and handwritten questions, and bulk uploading from excel sheets, it allows admins to easily manage exam content. The platform also supports notifications to selected students, exam setting customization, exam cancellation and re-copying, and AI-based proctoring during exams. This portal streamlines the entire exam process, making it more efficient and user-friendly for admins and students alike. Overall, this web portal is an ideal solution for institutes looking for a hassle-free exam conduction experience."
    }
    , {
        type: "Web_n_Back-end", name: "Proctur-Admin Web", link: "https://web.proctur.com/", time: "2021", skills: ["React.js", "Spring Boot", "AWS"], role: "React Developer",
        info: "Developed on React.js and Spring Boot, An Enhanced & Detailed web solution for a School/Inst/Coaching to perform all of theirs routine tasks e.g, Online Exams,Sell courses,Schedule live classes,Send Reports,Send Notification and Emails,View Schedules,Send notification,Send messages and mails,Answer query,Find schedule,Class Recording Vimeo and Video-Cipher, Send results,Mark Attendance,Admin Login,Faculty Login,Moderator's Login,Inventory management, many more"
    },
    {
        type: "Back-end", name: "Freelance Applications", link: "", time: "2021", skills: ["React.js","Spring Boot", "React Native", "Node.js", "AWS"], role: "React Developer",
        info: "In addition to my main projects, I have worked on several freelance web projects that have helped me to enhance my skills and gain more experience. These projects include designing and developing custom websites, both frontend and backend, with server hosting. I have also created portfolio websites that showcase my work and skills. Additionally, I have developed mobile applications for both iOS and Android platforms. These projects have enabled me to sharpen my coding skills, understand client requirements, and manage projects effectively. By working on these diverse projects, I have gained a deeper understanding of the different aspects of web development and have become more confident in my abilities."
    }

]

//                                          ==================== Contact

export const contact = [
    {
        text: "Call", detail: "+91-7651811808", link: "https://web.whatsapp.com/send/?phone=%2B917651811808&text&type=phone_number&app_absent=0",
        uri_scheme_phone_call: "tel://+917651811808", text_2_p: "WhatsApp",
        app_uri_scheme_phone: "whatsapp://send?phone=+917651811808"
    },
    {
        text: "Find Me On Linkdin",
        link: "https://www.linkedin.com/in/vishal-m-660a87266",
        // app_uri_scheme_phone: "linkedin://in/vishal-m-660a87266",
        detail: "LinkedIn"
    },
    {
        text: "Mail", detail: "vkm9002@gmail.com",
        // app_uri_scheme_phone: "googlegmail:///co?to=support@test.com&subject=Hello&body=Hi",
        // link: "googlegmail://co?subject=Example&body=ExampleBody",
        link: "https://mail.google.com/mail/?view=cm&fs=1&to=vkm9002@gmail.com&su=Meeting&body=Hi Vishal,\n",
    },
    // { text: "Mail", detail: "vkm9002@gmail.com", link: "https://mail.google.com/mail/?view=cm&fs=1&to=someone@example.com&su=SUBJECT&body=BODY&bcc=someone.else@example.com" },
    { text: "Website", detail: "www.mvishal.link", link: false },
    { text: "HackerRank", detail: "vishal_hbtu.k", link: "https://www.hackerrank.com/vishalhbtu_k" },
    { text: "Office", detail: "Noida, In", link: "" },
]

export const mail_data = {
    name: "", email: "",
    subj: "",
    msg: "",
}